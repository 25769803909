<template>
  <span>
    <span v-if="isModal" @click.prevent="toggle">
      <slot>
        <button class="cta yellow-cta">Contact Sales</button>
      </slot>
    </span>

    <Teleport to="body" :disabled="isEmbedded">
      <div v-if="open || isEmbedded" :class="isModal ? 'modal-wrapper tl' : null">

        <!-- only show the toggle if in modal state -->
        <div v-if="isModal" class="modal-bg" @click="toggle"></div>

        <div :class="isModal ? 'modal-window animation-appear-top relative near-black' : null">
          <div class="pa4">

            <!-- only show the toggle if in modal state -->
            <a v-if="isModal" href="#" @click.prevent="toggle"
              class="absolute ma4 top-0 right-0 f2 icon-remove moon-gray hover-silver-gray"></a>

            <div v-if="status === 'success'">
              <h2 class="text-extra-bold">{{ thanksHeader }}</h2>
              <p class="lh-copy">{{ thanksSubHead }}</p>

              <button name="commit" v-if="isModal" class="ph4 pv3 near-black bw0 br-pill b--gray pointer"
                @click.prevent="toggle">Close</button>
            </div>

            <div v-else-if="status === 'fatal'">
              <h2 class="text-extra-bold lh-title">Whoops, something went wrong.</h2>
              <p class="lh-copy">Please try back later.</p>

              <!-- only show close if in modal state -->
              <button v-if="isModal" name="commit" class="ph4 pv3 near-black bw0 br-pill b--gray pointer"
                @click.prevent="toggle">Close</button>
            </div>

            <div v-else>
              <h2 class="text-extra-bold f1">{{ header }}</h2>
              <p class="lh-copy">{{ subHead }}</p>

              <form action="/sales" accept-charset="UTF-8" method="post" @submit.prevent="submit" data-test="form">
                <div class="flex flex-column">
                  <input-field v-for="field in fields" :key="field.key" v-model="contact[field.key]"
                    :placeholder="field.placeholder" :required="field.required" :type="field.type" :errors="errorsFor(field.key)">
                    <span class="red" v-if="field.required">*</span>
                    {{ field.label }}
                  </input-field>
                </div>

                <div class="flex mt3">
                  <!-- only show cancel if modal -->
                  <button v-if="isModal" data-test="cancel" class="bw0 w-50 br-pill b--gray pointer" @click.prevent="toggle">
                    Cancel
                  </button>

                  <button data-posthog-action="contactSales" type="submit" data-test="cta"
                    class="bw0 ml2 cta w-50 pointer yellow-cta" :class="mode === 'embedded' ? 'w-100' : null" :disabled="sending">
                    {{ sending ? "Sending message..." : "Contact Sales"}}
                  </button>
                </div>

                <p class="pt3 f4 silver i">
                    In submitting this form, you agree to receive information from DNSimple related to our products,
                    events, and special offers. You can unsubscribe from such messages at any time. We never sell your
                    data, and we value your privacy choices. Please see our privacy policy for information.
                </p>
              </form>
            </div>

          </div>
        </div>
      </div>
    </Teleport>
  </span>
</template>

<script>
import Ajax from '../../javascript/common/ajax.js';
import InputField from './input-field.vue';

export default {
  name: 'contactSalesModal',

  components: { InputField },

  props: {
    handleEvent: { type: Boolean, default: false },
    mode: { type: String, default: 'modal' },
    requirePhone: { type: Boolean, default: true },
    thanksHeader: { type: String, default: "We've Received Your Request" },
    thanksSubHead: { type: String, default: 'Someone from our team will be in touch shortly.' },
    header: { type: String, default: 'Contact Sales' },
    subHead: { type: String, default: 'Reliable DNS architecture is critical to your Enterprise. You can\'t afford to waste time on the default DNS service from your domain registrar.' }
  },

  data () {
    return {
      errors: null,
      sending: false,
      status: null,
      open: false,

      fields: [
        { label: 'Your name', key: 'contact_name', placeholder: 'Your name', required: true },
        { label: 'Work email', key: 'work_email', placeholder: 'Your work email', required: true },
        { label: 'Company Name', key: 'company_name', placeholder: 'Your company name', required: true },
        { label: 'Work phone', key: 'phone', placeholder: 'Your phone number', required: this.requirePhone, type: 'tel' },
        { label: 'Tell us a bit about your current challenges with DNS and domain name management.', key: 'message', placeholder: 'How can we help you with DNS and domain management?', required: true, type: 'textarea' }
      ],

      hash: 'contact-sales',

      contact: {
        contact_name: '',
        work_email: '',
        company_name: '',
        message: '',
        phone: ''
      }
    };
  },

  mounted () {
    if (this.isEmbedded && window.location.hash === `#${this.hash}`) this.$el.scrollIntoView({ behavior: 'smooth' });

    if (this.handleEvent)
      window.addEventListener('openContactModal', () => this.open = true);
  },

  computed: {
    isModal () {
      return this.mode === 'modal';
    },

    isEmbedded () {
      return this.mode === 'embedded';
    }
  },

  watch: {
    open () {
      this.reset();
    }
  },

  methods: {

    errorsFor (field) {
      return this.errors?.[field];
    },

    toggle () {
      this.open = !this.open;
    },

    reset () {
      this.status = null;
      this.sending = false;
      this.errors = null;
    },

    async submit () {
      this.reset();
      this.sending = true;

      try {
        const payload = { ...this.contact };
        const { value: { success } } = await Ajax('/sales', { contact_sales: payload }, 'POST');
        if (success) this.status = 'success';
      } catch ({ statusCode, message }) {
        if (statusCode === 400) this.errors = message.errors;
        else this.status = 'fatal';
      }

      this.sending = false;
    }
  }
};
</script>
